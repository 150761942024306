import React, { useEffect, useState } from 'react';
import { Logs, Typography, Group, Wrapper, IconInfo, Tooltip, Token, Loader } from '@screentone/core';

import useImageDetail from '../../../hooks/useImageDetail';
import { constants, helpers, regex } from '../../../utils';
import type { ActivityType, EnvironmentType, PropertyType } from '../../../types';
import useConfig from '../../../hooks/useConfig';

import './style.css';

interface SpecificActionTypes {
  [key: string]: string;
}

function buildLogsItem(singleLog: ActivityType, env: EnvironmentType, property: PropertyType) {
  try {
    const {
      user = { display_name: '', given_name: '', family_name: '' },
      user_id,
      time_stamp,
      action_type,
      property_key,
      metadata,
    } = singleLog;
    const { ACTIVITY_OPTIONS } = constants;

    const specificActionTypes: SpecificActionTypes = {
      crop: ' image crop',
      gravity_thumb: 'thumbnail focal area',
      coordinates: 'focal area',
      published_label: 'slug',
    };
    const { currentMetadata, newMetadata, additionalData, tags } = metadata || {};

    if (!ACTIVITY_OPTIONS[action_type]?.showRelatedProperty && property_key !== property) {
      return;
    }
    let userName = user.display_name || user_id;
    if (['CLD_UPLOAD_NOTIFICATION', 'CLD_EAGER_NOTIFICATION'].includes(userName)) {
      userName = 'The image';
    }
    if (['CLD_PLACED_IN_ALLESSEH'].includes(userName)) {
      userName = '';
    }
    const date = new Date(time_stamp);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    const minsFormatted = minutes < 10 ? `0${minutes}` : minutes;

    const newMetadataObj = helpers.convertCldMetadataStringToObj(newMetadata);
    const items: any = [];

    Object.keys(newMetadataObj).forEach((metadataKey: string) => {
      let actionOptions = ACTIVITY_OPTIONS[action_type];
      if (metadataKey === 'tags') {
        const newTags = newMetadataObj[metadataKey].filter(
          (x: string) => !(currentMetadata?.[metadataKey] || []).includes(x),
        );

        newTags.forEach((item: string) => {
          const [value] = helpers.getCldMetadataLabel(item);

          const summary = `%USER% ${actionOptions.label || action_type}`
            .replace('%VALUE%', value || '')
            .replace(
              '%PROPERTY%',
              property_key === property
                ? ''
                : ` for ${constants.PROPERTY_LABELS[property_key as keyof typeof constants.PROPERTY_LABELS] || ''}`,
            )
            .replace('%TAG%', helpers.formatTag(item, property));

          items.push({
            timestamp: `${hours}:${minsFormatted}${ampm}`,
            summary,
            key: item,
            details: false,
            hide: ACTIVITY_OPTIONS[action_type].hide,
          });
        });

        const deletedTags = currentMetadata?.[metadataKey]?.filter(
          (x: string) => !(newMetadataObj?.[metadataKey] || []).includes(x),
        );

        deletedTags?.forEach((item: string) => {
          const [value] = helpers.getCldMetadataLabel(item);

          const summary = `%USER% ${actionOptions.label || action_type}`
            .replace('%VALUE%', value || '')
            .replace(
              '%PROPERTY%',
              property_key === property
                ? ''
                : ` for ${constants.PROPERTY_LABELS[property_key as keyof typeof constants.PROPERTY_LABELS] || ''}`,
            )
            .replace('%TAG%', helpers.formatTag(item, property));

          items.push({
            timestamp: `${hours}:${minsFormatted}${ampm}`,
            summary,
            key: item,
            details: false,
            hide: ACTIVITY_OPTIONS[action_type].hide,
          });
        });
      } else if (newMetadataObj[metadataKey] && action_type !== 'placed_in_allesseh') {
        Object.keys(newMetadataObj[metadataKey]).forEach((item: string) => {
          const newItem = newMetadataObj[metadataKey][item]?.toString() || '';
          const oldItem = currentMetadata?.[metadataKey]?.[item]?.toString() || '';
          const [value, baseKey] = helpers.getCldMetadataLabel(item);

          if (
            item === `last_modified_${property}` ||
            item === `tags_${property}` ||
            value === `published_labels_${property}`
          ) {
            return;
          }

          if (baseKey === 'disable_thumbnails_crop') {
            actionOptions = ACTIVITY_OPTIONS.disable_thumbnails_crop;
          }

          if (newItem !== oldItem || (typeof newItem === undefined && typeof oldItem === undefined)) {
            const summary = `%USER% ${actionOptions?.label || action_type}`
              .replace(
                '%VALUE%',
                specificActionTypes[value] ||
                  value ||
                  ACTIVITY_OPTIONS[value]?.label ||
                  action_type ||
                  actionOptions?.defaultValue ||
                  '',
              )
              .replace(
                '%BOOLEAN_VALUE%',
                actionOptions?.valueMap?.[`${newItem}` as 'true' | 'false'] || actionOptions?.defaultValue || '',
              )
              .replace(
                '%PROPERTY%',
                property_key === property
                  ? ''
                  : ` for ${constants.PROPERTY_LABELS[property_key as keyof typeof constants.PROPERTY_LABELS] || ''}`,
              );
            items.push({
              timestamp: `${hours}:${minsFormatted}${ampm}`,
              summary,
              key: item,
              hide: actionOptions?.hide,
              details: !actionOptions?.hideDetails && (!!currentMetadata || !!newMetadata),
              oldData: oldItem?.toString() || (
                <Typography variant="note">
                  <em>Empty Value</em>
                </Typography>
              ),
              newData: newItem || (
                <Typography variant="note">
                  <em>Empty Value</em>
                </Typography>
              ),
            });
          }
        });
      }
    });

    if (items.length) {
      return items.map((item: any) => {
        return (
          <Logs.Item
            key={`${time_stamp}_${item.key}`}
            timestamp={item.timestamp}
            user={userName}
            summary={item.summary}
            details={item.details && typeof item?.oldData == 'string' && !!item?.newData}
            oldData={item.oldData}
            newData={item.newData}
          />
        );
      });
    }

    let details = false;
    let newData = '';
    let text =
      ACTIVITY_OPTIONS[action_type]?.label?.replace(
        '%PROPERTY%',
        property_key === property
          ? ''
          : ` for ${constants.PROPERTY_LABELS[property_key as keyof typeof constants.PROPERTY_LABELS] || ''}`,
      ) || action_type;
    if (action_type === 'import' && metadata?.metadata?.import_source_name) {
      text += ` from ${metadata?.metadata?.import_source_name}`;
    }
    if (action_type === 'publish') {
      if (metadata?.published_label) {
        text = `${text.trim()}, with the ${metadata?.published_label} image set (${metadata?.published_id})`;
        details = metadata?.published_description && metadata?.published_description !== '';
        newData = metadata?.published_description;
      } else {
        text = `${text.trim()}, with the Default image set (${metadata?.published_id})`;
      }
    }
    if (action_type === 'crop') {
      text += ` for the ${
        metadata?.newMetadata?.context?.[`published_label_${metadata?.published_id}`] || 'Default'
      } image set (${metadata?.published_id})`;
    }
    if (action_type === 'focalpoint') {
      if (metadata?.additionalData?.published_label) {
        text += `, for ${metadata?.additionalData?.published_label || 'Default'} image set (${metadata?.published_id})`;
      } else {
        text += `, for the Default image set (${metadata?.published_id})`;
      }
    }
    if (action_type === 'add_tag' || action_type === 'remove_tag') {
      let tagList = tags?.filter((tag: string) => tag.toUpperCase().startsWith(`${property.toUpperCase()}`));
      if (!tagList || tagList.length === 0) {
        tagList = metadata?.tag && [metadata.tag];
      }

      const replaceValue = tagList?.map((tag: string) => helpers.formatTag(tag, property)).join(', ');
      if (!replaceValue) {
        return;
      }
      text = text.replace('%TAG%', `${tagList?.map((tag: string) => helpers.formatTag(tag, property)).join(', ')}`);
    }

    if (action_type === 'placed_in_allesseh') {
      text = text
        .replace('%ARTICLE_LINK%', '')
        .replace('%PRODUCT%', additionalData?.metadata?.product || '')
        .replace(
          '%PROPERTY%',
          property_key === property
            ? ''
            : ` for ${constants.PROPERTY_LABELS[property_key as keyof typeof constants.PROPERTY_LABELS] || ''}`,
        );
      const getCMSUrl = (originId: string) => {
        // is NewsPress / NewsGrid ID
        if (!!regex.newGridIdRegex.exec(originId)) {
          return `https://newsgrid.${env === 'prd' ? '' : `${env}.`}dowjones.io/article/${originId}`;

          // is Live Coverage
        } else if (!!regex.liveCoverageIdRegex(property).exec(originId)) {
          let lcSlug = originId.replace(`lc-${property}-`, '');

          // check if id has cardId and remove it
          const hasCardId = regex.liveCoverageIdRegexWithCardId.exec(lcSlug);
          if (hasCardId?.groups?.cardId) {
            lcSlug = lcSlug.replace(hasCardId.groups.cardId, '');
          }
          return `https://livecoverage.${env === 'prd' ? '' : `${env}.`}dowjones.io/${property}/news/event/${lcSlug}`;
        }

        // catch all
        return `https://consumer-tools.dowjones.net/publishing_article?env=${
          env === 'prd' ? 'PROD' : 'SAT'
        }&id=${originId}`;
      };

      return (
        <li key={`${time_stamp}`}>
          <Wrapper padding={{ all: 'sm' }}>
            <Group fullWidth direction="row" gap="md">
              <Typography variant="note">{`${hours}:${minsFormatted}${ampm}`}</Typography>
              <Group.Item flex>
                <Typography color="ink" componentEl="div">
                  {text}
                  {additionalData?.metadata?.source_url && (
                    <Typography
                      variant="link"
                      target="_blank"
                      rel="noreferrer"
                      href={additionalData?.metadata?.source_url}
                      inline
                    >
                      {additionalData?.metadata?.headline.text}
                    </Typography>
                  )}
                  {additionalData?.metadata?.upstream_origin_id && (
                    <Typography size="sm" inline>
                      {' '}
                      [
                      <Typography
                        variant="link"
                        target="_blank"
                        rel="noreferrer"
                        href={getCMSUrl(additionalData?.metadata?.upstream_origin_id)}
                        inline
                      >
                        {additionalData?.metadata?.upstream_origin_id}
                      </Typography>
                      ]
                    </Typography>
                  )}
                </Typography>
              </Group.Item>
            </Group>
          </Wrapper>
        </li>
      );
    }

    if (ACTIVITY_OPTIONS[action_type]?.hide) {
      return;
    }
    return (
      <Logs.Item
        key={`${time_stamp}`}
        timestamp={`${hours}:${minsFormatted}${ampm}`}
        user={userName}
        summary={`%USER% ${text}`}
        details={details}
        oldData={
          <Typography variant="note">
            <em>Empty Value</em>
          </Typography>
        }
        newData={newData}
      />
    );
  } catch (error) {
    console.error('ACTIVITY LOGSerror: ', error);
    return;
  }
}

function buildLogsGroup(activityLogs: ActivityType[], env: EnvironmentType, property: PropertyType) {
  const groupsObj: any = {};
  const groupsKeyTracker = [];

  try {
    activityLogs.sort((a, b) => {
      return new Date(Number(b.time_stamp)).getTime() - new Date(Number(a.time_stamp)).getTime();
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < activityLogs.length; i++) {
      const singleLog = activityLogs[i];
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { time_stamp } = singleLog;
      const date = new Date(time_stamp);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const groupKey = `${year}-${month}-${day}`;
      const item = buildLogsItem(singleLog, env, property);

      if (item) {
        if (groupsObj[groupKey as string]) {
          groupsObj[groupKey].items.push(item);
        } else {
          groupsObj[groupKey] = {
            formattedTimeStamp: `${date.toLocaleString('default', { month: 'short' })} ${day}, ${year}`,
            items: [item],
          };

          groupsKeyTracker.push(groupKey);
        }
      }
    }
  } catch (err) {
    console.error('Error building logs group', err);
  }

  return { groupsKeyTracker, groupsObj };
}

function ActivityLogs({ env, property }: { env: EnvironmentType; property: PropertyType }) {
  const { authFetch } = useConfig();
  const { image, setActivityLogs, activityLogs } = useImageDetail();
  const [isLoadingActivity, setIsLoadingActivity] = useState(!activityLogs);
  const { groupsKeyTracker, groupsObj } = buildLogsGroup(activityLogs || [], env, property);

  useEffect(() => {
    setIsLoadingActivity(true);
    authFetch(`/api/:property/${encodeURIComponent(image?.public_id)}/activity`)
      .then((activity: ActivityType[]) => {
        setActivityLogs(activity);
        setIsLoadingActivity(false);
      })
      .catch((err: Error) => {
        console.error('Error fetching activity', err);
        throw err;
        // TODO: Add error handling
      });
  }, [image]);

  return (
    <Logs margin={{ horizontal: 'none', top: 'xs', bottom: 'sm' }}>
      <Group gap="none">
        <Typography componentEl="h3" size="md" margin={{ top: 'none', bottom: 'sm' }}>
          <Typography weight="med" inline data-testid="image-history">
            Image History
          </Typography>{' '}
          <Tooltip>
            <Tooltip.Content position="bottom">
              <em>Note: this is a BETA feature and is subject to change.</em>
            </Tooltip.Content>
            <Tooltip.Trigger aria-describedby="tooltipContent">
              <Token margin={{ right: 'md' }} icon={IconInfo}>
                BETA
              </Token>
            </Tooltip.Trigger>
          </Tooltip>
          {isLoadingActivity && (
            <>
              <Loader size="md" style={{ verticalAlign: 'text-bottom' }} />{' '}
              <Typography size="sm" weight="normal" inline>
                Loading...
              </Typography>
            </>
          )}
        </Typography>
      </Group>

      {activityLogs &&
        groupsKeyTracker.map((groupsKey) => (
          <>
            {groupsObj[groupsKey].items.length > 0 && (
              <Logs.Group
                key={groupsKey}
                timestamp={groupsObj[groupsKey].formattedTimeStamp}
                data-testid="img-history-logs"
              >
                {groupsObj[groupsKey].items}
              </Logs.Group>
            )}
          </>
        ))}

      {activityLogs && !groupsKeyTracker.length && (
        <Typography componentEl="p" size="md" margin={{ bottom: 'sm' }}>
          Activity logs are not available for this image
        </Typography>
      )}
    </Logs>
  );
}

export default ActivityLogs;
